import { NextSeo, NextSeoProps } from 'next-seo';

interface SeoProps extends NextSeoProps {
  url: string;
  images: any[];
  ogTitle?: string;
}
const Seo = ({ title, ogTitle, description, images, url, ...props }: SeoProps) => (
    <NextSeo
      title={title}
      openGraph={{
        // url: `${process.env.NEXT_PUBLIC_SITE_URL}/${url}`,
        title: ogTitle,
        description,
        images: images?.map((item) => ({
          url: item?.image?.original,
          alt: item?.title,
        })),
      }}
      {...props}
    />
  );

export default Seo;
